<!--登录界面-->
<style scoped lang="less">
html,
body {
  height: 100%;
}
.index {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  font-size: 14px;
  height: 100%;
}
.login-bg {
  background: url(https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yingjianguanlixitong/%E7%99%BB%E5%BD%95-%E5%AE%BD%E5%B1%8F%E9%80%82%E9%85%8D%E8%A7%84%E5%88%99.png)
    no-repeat center center #000;
  height: 100%;
  background-size: cover;
}
.login-right {
  background-color: #fff;
  width: 400px;
  position: absolute;
  top: 50%;
  right: 11.5%;
  height: 558px;
  margin-top: -279px;
  box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.1);
  border-radius: 8px;
}
.login-main {
  width: 400px;
  margin: 0 auto;
  position: relative;
  em.bg {
    background-color: #fff;
    border-radius: 4px;
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
  }
}
.index h1 {
  font-size: 22px;
  color: #37364c;
  margin: 120px auto 60px auto;
}
.index h2 {
  font-size: 20px;
  padding: 55px 40px 40px 40px;
  color: #333;
  font-size: 24px;
  line-height: 34px;
  text-align: left;
}
.login-form {
  padding: 0 40px;
  margin: 40px auto;
}
.index .ivu-row-flex {
  height: 100%;
}
p.copy {
  position: absolute;
  bottom: 40px;
  color: #999;
  text-align: left;
  padding-left: 100px;
  font-size: 12px;
}
</style>
<template>
  <div class="index" @keyup.enter="handleSubmit('formInline')">
    <div class="login-bg"></div>
    <!--<Row clsss="" type="flex" justify="center" align="middle">-->
    <!--<Col class="login-main" span="24">-->
    <!--<em class="bg"></em>-->
    <div class="login-right">
      <h2>登录</h2>
      <Form
        class="login-form"
        ref="formInline"
        :model="formInline"
        :rules="ruleInline"
      >
        <FormItem prop="user">
          <Input type="text" v-model="formInline.user" placeholder="账号">
            <Icon type="ios-person" slot="prepend"></Icon>
          </Input>
        </FormItem>
        <FormItem prop="password">
          <Input
            type="password"
            v-model="formInline.password"
            placeholder="密码"
          >
            <Icon type="ios-lock" slot="prepend"></Icon>
          </Input>
        </FormItem>
        <FormItem>
          <Button
            type="success"
            style="background-color: #0066FF;border-color:#0066FF;margin-top:20px;"
            :loading="loading"
            long
            @click="handleSubmit('formInline')"
            >登录</Button
          >
        </FormItem>
      </Form>
    </div>
    <!--</Col>-->
    <!--</Row>-->
    <p class="copy">
      © {{ new Date().getFullYear() }} 「杭州快驰科技」产品研发部出品
      <a href="//beian.miit.gov.cn/" target="_blank">浙ICP备14034118号-1</a>
    </p>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import { ajax } from '../libs/util'
export default {
  data() {
    return {
      loading: false,
      formInline: {
        user: '',
        password: '',
      },
      ruleInline: {
        user: [{ required: true, message: '请填写账号', trigger: 'blur' }],
        password: [
          { required: true, message: '请填写密码', trigger: 'blur' },
          {
            type: 'string',
            min: 6,
            message: '密码长度不能小于6位',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    handleSubmit(name) {
      let that = this
      that.$refs[name].validate((valid) => {
        if (valid) {
          that.loading = true
          ajax.post(
            '/user/login',
            {
              phone: that.formInline.user,
              password: that.formInline.password,
            },
            (res) => {
              Cookies.set('token', res.data.token, { path: '/' })
              Cookies.set('name', res.data.userinfo.name, { path: '/' })
              that.$router.push({ path: 'index' })
            },
            (error) => {
              that.loading = false
              that.$Message.error(error.message || error.msg || '服务器异常，请重试')
            }
          )
        }
      })
    },
  },
  created() {
    if (Cookies.get('token')) {
      this.$router.push({ path: 'index' })
    }
  },
  mounted() {},
}
</script>
